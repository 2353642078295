<!-- APP用户列表 -->
<template>
  <div>
    <div>
      <div class="tool">
        <div>
          <a-select v-model:value="field" @change="onFieldChange">
            <a-select-option value="username">用户名</a-select-option>
            <a-select-option value="realname">姓名</a-select-option>
            <a-select-option value="nickname">昵称</a-select-option>
          </a-select>
          <a-input-search
            v-model:value="keyword"
            placeholder="请输入关键词"
            enter-button
            @search="onSearch"
            @change.stop="onSearchChange"
          />
          <a-button type="primary" @click="onAdvanceSearch" disabled>
            <SvgIcon iconName="search" className="svg16" />高级搜索
          </a-button>
        </div>
      </div>
    </div>
    <div v-show="showSearchArea" class="searchAreaCss">
      <SearchArea @initUserList="initUserList" />
    </div>
    <div style="margin-top: 6px">
      <a-table
        :scroll="{ x: 2100 }"
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="userList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        <template #action="{ record }">
          <span>
            <a-button
              type="link"
              size="small"
              @click="doSetAppUserKey(record, 1)"
              :disabled="!record.appUserKeyInfo.ukey == null"
            >
              <PlusOutlined />生成密钥
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button
              type="link"
              size="small"
              @click="doSetAppUserKey(record, 2)"
              :disabled="record.appUserKeyInfo.ukey == null"
            >
              <RedoOutlined />重置密钥
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button
              type="link"
              size="small"
              @click="doSetAppUserKey(record, 3)"
              :disabled="!record.appUserKeyInfo.enableUk == null"
            >
              <SafetyCertificateOutlined />开通密钥
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button
              type="link"
              size="small"
              @click="doSetAppUserKey(record, 4)"
              :disabled="record.appUserKeyInfo.enableUk == null"
            >
              <StopOutlined />禁用密钥
            </a-button>
          </span>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  StopOutlined,
  PlusOutlined,
  SafetyCertificateOutlined,
  RedoOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted, defineComponent, createVNode } from "vue";
import { getAppUserKeysPage } from "@/api/user";
import { updateAppUserKey } from "@/api/account/account";
import moment from "moment";
import SearchArea from "../../user/userlist/components/mySearchArea.vue";
import { message, Modal } from "ant-design-vue";
import SvgIcon from "@/components/Svgicon";
export default defineComponent({
  name: "AppKey",
  components: {
    StopOutlined,
    PlusOutlined,
    SafetyCertificateOutlined,
    RedoOutlined,
    SearchArea,
    SvgIcon,
  },
  setup() {
    const state = reactive({
      field: "username",
      keyword: "",
      loading: false,
      selectedRowKeys: [],
      showSearchArea: false,
      headers: {
        authorization: window.sessionStorage.getItem("token"),
      },
      modalVisible: false,
      searchParam: {
        searchField: "",
        searchValue: "",
        pageIndex: 1,
        pageSize: 10,
      },
      userList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "姓名",
        dataIndex: "realname",
        key: "realname",
        width: 100,
        fixed: "left",
      },
      {
        title: "工号",
        dataIndex: "code",
        key: "code",
        width: 100,
      },
      {
        title: "用户名",
        dataIndex: "username",
        key: "username",
        width: 120,
      },
      {
        title: "昵称",
        dataIndex: "nickname",
        key: "nickname",
        width: 120,
      },
      {
        title: "密钥",
        dataIndex: "appUserKeyInfo.ukey",
        key: "ukey",
        width: 200,
      },
      {
        title: "用户状态",
        dataIndex: "userStatusInfo.item",
        key: "userStatus",
        width: 120,
      },
      {
        title: "授权状态",
        dataIndex: "authorizeStatusInfo.item",
        key: "authorizeStatus",
        width: 120,
      },
      {
        title: "手机",
        dataIndex: "phone",
        key: "phone",
        width: 150,
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        width: 150,
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
        },
      },
      {
        title: "备注",
        dataIndex: "memo",
        key: "memo",
        width: 300,
      },
      {
        title: "操作",
        align: "center",
        key: "action",
        width: 500,
        fixed: "right",
        slots: {
          customRender: "action",
        },
      },
    ];
    onMounted(() => {
      if (state.searchValue) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      initUserList(state.searchParam);
    });
    const initUserList = (searchParam) => {
      state.loading = true;
      getAppUserKeysPage(searchParam).then((resp) => {
        if (resp) {
          state.userList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onSearch = () => {
      state.searchParam = {
        searchField: state.field,
        searchValue: state.keyword,
        pageIndex: 1,
        pageSize: 10,
      };
      initUserList(state.searchParam);
    };
    const onAdvanceSearch = () => {
      state.showSearchArea = !state.showSearchArea;
    };
    const onSearchChange = () => {
      //state.keyword = e.data;
      state.searchParam.searchValue = state.keyword;
      state.searchParam.searchField = state.field;
    };
    const onFieldChange = (value) => {
      state.field = value;
      state.searchParam.searchField = state.field;
    };
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys;
    };
    const onChangePage = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initUserList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initUserList(state.searchParam);
    };
    const rowSelection = {
      onChange: (selectedRowKeys) => {
        state.selectedRowKeys = selectedRowKeys;
      },
    };
    const doSetAppUserKey = (record, tp) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将" +
            (tp == 1 ? "创建" : tp == 2 ? "重置" : "禁用") +
            "APP用户【" +
            record.username +
            "】的密钥，是否继续？"
        ),
        onOk() {
          updateAppUserKey({
            uid: record.id,
            val: !record.appUserKeyInfo.enable_uk,
            tp: tp,
          }).then((resp) => {
            if (resp) {
              initUserList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info(
            "已取消APP用户密钥" +
              (tp == 1 ? "创建" : tp == 2 ? "重置" : "禁用") +
              "操作！！！"
          );
          //   initUserList(state.searchParam);
        },
      });
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initUserList,
      onSearch,
      onAdvanceSearch,
      onSearchChange,
      onFieldChange,
      onChangePage,
      onShowSizeChange,
      onSelectChange,
      rowSelection,
      doSetAppUserKey,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}
.ant-btn {
  margin-right: 5px;
}
.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
.upload {
  display: inline-flex;
}
.searchAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}
</style>